import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import perusahaan from "../assets/img/Bobcat_Vector.png";
import perusahaan1 from "../assets/img/Truck_Vector1.png";
import perusahaan2 from "../assets/img/Truck_Vector2.png";
import tepat from "../assets/img/tepat.png";
import cepat from "../assets/img/cepat.png";
import cermat from "../assets/img/cermat.png";
import tanggungJawab from "../assets/img/tanggung_jawab.png";
import location from "../assets/img/location.png";
import image1 from "../assets/img/Image1.png";
import image2 from "../assets/img/Image2.png";
import image3 from "../assets/img/Image3.png";
import image4 from "../assets/img/Image4.png";
import jakarta1 from "../assets/img/jakarta1.png";
import jakarta2 from "../assets/img/jakarta2.png";
import jakarta3 from "../assets/img/jakarta3.png";
import jakarta4 from "../assets/img/jakarta4.png";
import jakarta5 from "../assets/img/jakarta5.png";
import jakarta6 from "../assets/img/jakarta6.png";
import jakarta7 from "../assets/img/jakarta7.png";
import jakarta8 from "../assets/img/jakarta8.png";
import jakarta9 from "../assets/img/jakarta9.png";
import jakarta10 from "../assets/img/jakarta10.png";
import bandung1 from "../assets/img/bandung1.png";
import bandung2 from "../assets/img/bandung2.png";
import bandung3 from "../assets/img/bandung3.png";
import bandung4 from "../assets/img/bandung4.png";
import bandung5 from "../assets/img/bandung5.png";
import bandung6 from "../assets/img/bandung6.png";
import bandung7 from "../assets/img/bandung7.png";
import bandung8 from "../assets/img/bandung8.png";
import bandung9 from "../assets/img/bandung9.png";
import bandung10 from "../assets/img/bandung10.png";
import bekasi1 from "../assets/img/bekasi1.png";
import bekasi2 from "../assets/img/bekasi2.png";
import bekasi3 from "../assets/img/bekasi3.png";
import tangerang1 from "../assets/img/tangerang1.png";
import tangerang2 from "../assets/img/tangerang2.png";
import tangerang3 from "../assets/img/tangerang3.png";
import cianjur1 from "../assets/img/cianjur1.png";

const LandingPage = () => {
  const testimonials = [
    {
      id: 1,
      image: image1,
      name: "Yanti Ariyantika",
      position: "PPIC PT. Tropica Mas Pharmaceuticals",
      testimonial:
        "Kami sudah mengunakan jasa ekspedisi PT. Kalpika Logistic sejak tahun 2010. Kami sangat puas dengan kecepatan dan ketepatan pengirimannya. Jika ada perubahan jadwal pengambilan dan pengiriman barang, respon dari Kalpika Logistic sangat ramah dan cepat. Oleh karenanya, kami selalu menggunakan jasa eskpedisi Kalpika Logistic dan tidak mau beralih ke ekspedisi lainnya.",
    },
    {
      id: 2,
      image: image2,
      name: "Teguh Kristianto",
      position: "Inventory Manager PT. Signa Husada",
      testimonial:
        "PT Kalpika Logistic merupakan salah satu transporter yang sudah menjadi mitra kami dalam mendistribusikan bahan baku farmasi sejak tahun 2012. Transporter yang dapat diandalkan dari sisi keamanan dan kecepatan. Kami pernah komplain terhadap barang yang rusak, mereka bertanggungjawab dan mengganti biaya kerugiannya. Maju terus dan semakin sukses untuk PT Kalpika Logistic.",
    },
    {
      id: 3,
      image: image3,
      name: "Khansaa",
      position: "SPV Sales dan Distribution PT. Holi Pharma",
      testimonial:
        "PT Kalpika Logistic sudah menjadi mitra kami dalam mendistribusikan obat-obatan sejak tahun 2014. Transporter yang dapat diandalkan dari sisi kemananan pengiriman dan kecepatan pengiriman. Dari segi keluhan pelanggan dan kerusakan barang juga langsung diproses dan diselesaikan dengan baik. Sukses terus untuk PT. Kalpika Logistic.",
    },
    {
      id: 4,
      image: image4,
      name: "Diva Bayu Pratiwi",
      position: "Logistic Staff PT.Panda Mas Kimia Abadi",
      testimonial:
        "Kami mengenal dan menggunakan jasa ekspedisi PT. Kalpika Logistic sejak tahun 2018, alasan kami memilih PT. Kalpika Logistic sebagai pilihan mitra kerja kami karena dapat dipercaya , pengiriman tepat waktu , sigap dalam menangani masalah , respons cepat terhadap keluhan dari customer kami. PT. Kalpika Logistic berkembang pesat dalam beberapa tahun ini sehingga kami tidak meragukan meningkatkan kerjasama lebih baik lagi. Semoga semakin maju dan sukses selalu.",
    },
    // Add more testimonials as needed
  ];

  const icon = [
    {
      title: "Jakarta",
      images: [
        {
          id: 1,
          image: jakarta1,
        },
        {
          id: 2,
          image: jakarta2,
        },
        {
          id: 3,
          image: jakarta3,
        },
        {
          id: 4,
          image: jakarta4,
        },
        {
          id: 5,
          image: jakarta5,
        },
        {
          id: 6,
          image: jakarta6,
        },
        {
          id: 7,
          image: jakarta7,
        },
        {
          id: 8,
          image: jakarta8,
        },
        {
          id: 9,
          image: jakarta9,
        },
        {
          id: 10,
          image: jakarta10,
        },
      ],
    },
    {
      title: "Bandung",
      images: [
        {
          id: 1,
          image: bandung1,
        },
        {
          id: 2,
          image: bandung2,
        },
        {
          id: 3,
          image: bandung3,
        },
        {
          id: 4,
          image: bandung4,
        },
        {
          id: 5,
          image: bandung5,
        },
        {
          id: 6,
          image: bandung6,
        },
        {
          id: 7,
          image: bandung7,
        },
        {
          id: 8,
          image: bandung8,
        },
        {
          id: 9,
          image: bandung9,
        },
        {
          id: 10,
          image: bandung10,
        },
      ],
    },
    {
      title: "Bekasi",
      images: [
        {
          id: 1,
          image: bekasi1,
        },
        {
          id: 2,
          image: bekasi2,
        },
        {
          id: 3,
          image: bekasi3,
        },
      ],
    },
    {
      title: "Tangerang",
      images: [
        {
          id: 1,
          image: tangerang1,
        },
        {
          id: 2,
          image: tangerang2,
        },
        {
          id: 3,
          image: tangerang3,
        },
      ],
    },
    {
      title: "Cianjur",
      images: [
        {
          id: 1,
          image: cianjur1,
        },
      ],
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlidePartner, setCurrentSlidePartner] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === testimonials.length - 1 ? 0 : prevSlide + 1
    );
  };
  const nextSlidePartner = () => {
    setCurrentSlidePartner((prevSlide) =>
      prevSlide === icon.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? testimonials.length - 1 : prevSlide - 1
    );
  };
  const prevSlidePartner = () => {
    setCurrentSlidePartner((prevSlide) =>
      prevSlide === 0 ? icon.length - 1 : prevSlide - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlidePartner, 2000); // Change slide every 3 seconds
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [currentSlidePartner]);
  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Change slide every 3 seconds
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [currentSlide]);

  return (
    <>
      {/* hero */}
      <div className="bg-custom-pattern bg-cover bg-center min-h-[30vh] lg:h-[95vh] flex lg:items-end lg:justify-start items-center mt-24">
        <h1 className="text-[24px] lg:text-[48px] font-bold mb-4 text-white lg:pl-24 lg:pb-24 mx-auto lg:mx-0 text-shadow-md font-gilroyExtraBold">
          Tepat, Cepat, Cermat
          <br /> dan Bertanggung Jawab
        </h1>
      </div>

      {/* perusahaan */}
      <section id="perusahaan" className="lg:pt-[90px] pt-[60px]">
        <h1 className="text-center text-primary text-xl lg:text-[35px] lg:mb-[70px] mt-6 font-bold font-gilroyBold">
          Apa Sih Kalpika Logistic Itu ?
        </h1>
        <div className="grid lg:grid-cols-2 gap-8 sm:grid-cols-1 lg:my-12 lg:mx-32">
          {/* Content for the first column */}
          <div className="lg:col-span-1 sm:col-span-full mx-auto lg:mx-0 mt-8 lg:mt-0 flex justify-end">
            {" "}
            <img
              src={perusahaan}
              alt="kalpika logo"
              className="mr-2 lg:ml-auto max-w-[250px] max-h-[150px] lg:max-w-[550px] lg:max-h-[300px]"
            ></img>
          </div>

          {/* Content for the second column */}
          <div className="lg:col-span-1 sm:col-span-full mx-4">
            <h5 className="text-[14px] lg:text-[20px]  text-justify  font-light font-gilroyReg lg:py-12">
              Kalpika Logistic bergerak dalam bidang Jasa Logistik Angkutan
              Darat yang berdiri sejak Oktober 2010. Kalpika Logistic siap
              menjadi mitra usaha anda dalam membantu memenuhi kebutuhan
              distribusi barang sampai ke tujuan dengan selamat dan tepat waktu.
            </h5>
          </div>
        </div>
      </section>

      {/* moto */}
      <section className="lg:pt-[50px]">
        <h1 className="text-center text-primary text-xl lg:text-[35px] font-bold tracking-wide mt-6 lg:mb-[70px] font-gilroyBold">
          Motto Kalpika Logistic
        </h1>
        <div className="grid xl:grid-cols-4 lg:grid-cols-2 gap-4  lg:my-12 lg:mx-32">
          <div className="lg:col-span-1 sm:col-span-full mx-auto lg:mx-0 mt-8 lg:mt-0">
            <div className="max-w-sm bg-white">
              <img
                className="rounded-t-lg mx-auto w-[100px] lg:h-[200px] lg:w-[150px]"
                src={tepat}
                alt=""
              />
              <div className="p-1 lg:mt-12 text-center">
                <h5 className="mb-2 text-md lg:text-2xl tracking-tight font-gilroyBold">
                  Tepat
                </h5>
                <p className="mb-3 text-[14px] lg:text-xl font-gilroyLight">
                  Tepat waktu dalam mengantarkan barang
                </p>
              </div>
            </div>
          </div>
          <div className="lg:col-span-1 sm:col-span-full mx-auto lg:mx-0 mt-8 lg:mt-0">
            <div className="max-w-sm bg-white">
              <img
                className="rounded-t-lg mx-auto w-[150px] lg:h-[200px] lg:w-[200px]"
                src={cepat}
                alt=""
              />
              <div className="p-1 lg:mt-12 text-center">
                <h5 className="mb-2 text-md lg:text-2xl tracking-tight font-gilroyBold">
                  Cepat
                </h5>
                <p className="mb-3 text-[14px] lg:text-xl font-gilroyLight">
                  Cepat dalam mengirimkan barang
                </p>
              </div>
            </div>
          </div>
          <div className="lg:col-span-1 sm:col-span-full mx-auto lg:mx-0 mt-8 lg:mt-0">
            <div className="max-w-sm bg-white">
              <img
                className="rounded-t-lg mx-auto w-[120px] lg:h-[200px] lg:w-[200px]"
                src={cermat}
                alt=""
              />
              <div className="p-1 lg:mt-12 text-center">
                <h5 className="mb-2 text-md lg:text-2xl tracking-tight font-gilroyBold">
                  Cermat
                </h5>
                <p className="mb-3 text-[14px] lg:text-xl font-gilroyLight">
                  Cermat dalam mengelola barang-barang
                </p>
              </div>
            </div>
          </div>
          <div className="lg:col-span-1 sm:col-span-full mx-auto lg:mx-0 mt-8 lg:mt-0">
            <div className="max-w-sm bg-white">
              <img
                className="rounded-t-lg mx-auto w-[150px] lg:h-[200px] lg:w-[200px]"
                src={tanggungJawab}
                alt=""
              />
              <div className="p-1 lg:mt-12 text-center">
                <h5 className="mb-2 text-md lg:text-2xl tracking-tight font-gilroyBold">
                  Tanggung Jawab
                </h5>
                <p className="mb-3 text-[14px] lg:text-xl font-gilroyLight">
                  Bertanggung jawab atas keluhan dan aduan pelanggan
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* testimoni */}
      <section id="testimoni" className="lg:pt-[120px] pt-[90px]">
        <h1 className="text-center text-primary text-xl lg:text-[35px] font-bold tracking-wide lg:mb-[20px] font-gilroyBold">
          Apa Kata Mereka
        </h1>

        <div className="relative w-full max-w-full mx-auto ">
          <div className="overflow-hidden relative h-[380px]">
            {testimonials.map((testimonial, index) => (
              <Transition
                key={index}
                show={index === currentSlide}
                enter="transition-opacity duration-1000"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-1000"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="absolute inset-0"
              >
                <div className="grid lg:grid-cols-3 gap-8 sm:grid-cols-1 lg:my-12 lg:mx-32">
                  <div className="lg:col-span-1 sm:col-span-full mx-auto lg:mx-0 mt-8 lg:mt-0 lg:ml-auto">
                    <div className="flex flex-col items-center justify-center text-center">
                      <img
                        className=" mr-2 w-[100px] h-[100px] lg:w-[200px] lg:h-[200px] rounded-full"
                        src={testimonial.image}
                        alt={testimonial.name}
                        priority
                      />
                      <h5 className="text-center font-gilroyBold italic text-[14px] lg:text-[22px] mt-3">
                        {testimonial.name}
                      </h5>
                      <p className="text-center font-gilroyReg font-light text-[14px] lg:text-[18px]">
                        {testimonial.position}
                      </p>
                    </div>
                  </div>
                  <div className="lg:col-span-2 sm:col-span-full mx-auto px-6 mb-8">
                    <h5 className="text-[14px] lg:text-[20px] text-justify font-light font-gilroyReg">
                      {testimonial.testimonial}
                    </h5>
                  </div>
                </div>
              </Transition>
            ))}
          </div>

          <button
            onClick={prevSlide}
            type="button"
            class="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-prev
          >
            <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <svg
                class="w-4 h-4 text-black dark:text-gray-800 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
              <span class="sr-only">Previous</span>
            </span>
          </button>
          <button
            onClick={nextSlide}
            type="button"
            class="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-next
          >
            <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <svg
                class="w-4 h-4 text-black dark:text-gray-800 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span class="sr-only">Next</span>
            </span>
          </button>
        </div>
      </section>

      {/* lokasi */}
      <section id="lokasi" className="lg:pt-[100px] pt-[90px]">
        <h1 className="text-center text-primary text-xl lg:text-[35px] font-bold tracking-wide mt-6  font-gilroyBold">
          Lokasi Kalpika Logistic
        </h1>
        <div className="grid grid-cols-1 lg:mx-24  my-14">
          <div className="lg:col-span-1 sm:col-span-full mx-4 mb-8">
            <div className="flex items-center justify-between ">
              <a
                target="_blank"
                href="https://www.google.com/maps/place/Kalpika+Logistics/@-6.9232953,107.5721823,17z/data=!3m1!4b1!4m6!3m5!1s0x2e68e72324377d4d:0x9cdeafda43de7a6d!8m2!3d-6.9233006!4d107.5747572!16s%2Fg%2F11r3s6fv_q?entry=ttu"
              >
                <div className="flex">
                  <img
                    src={location}
                    alt="kalpika logo"
                    className="mr-2 max-w-[30px] max-h-[30px]  lg:max-w-[40px] lg:max-h-[30px]"
                  ></img>

                  <div className="flex flex-col">
                    <h5 className="font-gilroyBold text-[16px] lg:text-[20px]">
                      Bandung
                    </h5>
                    <p className="font-gilroyLight text-[14px] lg:text-[16px]">
                      Jl. Soekarno Hatta No. 56, Cibuntu, Kota Bandung 40212
                    </p>
                  </div>
                </div>
              </a>
              <div className="w-full lg:w-[400px] h-3/4 lg:h-[300px] p-4 hidden lg:block">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.7262199107927!2d107.57218227592931!3d-6.923295267766424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e72324377d4d%3A0x9cdeafda43de7a6d!2sKalpika%20Logistics!5e0!3m2!1sid!2sid!4v1717384454138!5m2!1sid!2sid"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="lg:col-span-1 sm:col-span-full mx-4 mb-8">
            <div className="flex items-center justify-between ">
              <div className="w-full lg:w-[400px] h-3/4 lg:h-[300px] p-4 hidden lg:block">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d507341.851941866!2d106.91000001384683!3d-6.571030180697019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3f88ab98eb7%3A0x818bcb71cee802f6!2sPT%20KALPIKA%20LOGISTIC%20CAB.%20JAKARTA!5e0!3m2!1sid!2sid!4v1717385505725!5m2!1sid!2sid"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <a
                target="_blank"
                href="https://www.google.com/maps/dir/-6.9431032,107.5736617/PT+KALPIKA+LOGISTIC+CAB.+JAKARTA,+Jl.+KH.+Maisin.119RT.7,+RT.7%2FRW.16,+Klender,+Kec.+Duren+Sawit,+Kota+Jakarta+Timur,+Daerah+Khusus+Ibukota+Jakarta+13470/@-6.5768883,106.5803545,9z/data=!3m1!4b1!4m19!1m8!3m7!1s0x2e69f3f88ab98eb7:0x818bcb71cee802f6!2sPT+KALPIKA+LOGISTIC+CAB.+JAKARTA!8m2!3d-6.2203399!4d106.9045381!15sChlLYWxwaWthIExvZ2lzdGljcyBqYWthcnRhkgEQc2hpcHBpbmdfY29tcGFueeABAA!16s%2Fg%2F11thg0wb8s!4m9!1m1!4e1!1m5!1m1!1s0x2e69f3f88ab98eb7:0x818bcb71cee802f6!2m2!1d106.9045744!2d-6.2203523!3e9?entry=ttu"
              >
                <div className="flex ">
                  <img
                    src={location}
                    alt="kalpika logo"
                    className="mr-2 max-w-[30px] max-h-[30px]  lg:max-w-[40px] lg:max-h-[30px]"
                  ></img>
                  <div className="flex flex-col">
                    <h5 className="font-gilroyBold text-[16px] lg:text-[20px]">
                      Jakarta
                    </h5>
                    <p className="font-gilroyLight text-[14px] lg:text-[16px]">
                      Jl. Dermaga Raya No. 99, Duren Sawit, Jakarta Timur 13440
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="lg:col-span-1 sm:col-span-full mx-4 mb-8">
            <div className="flex items-center justify-between ">
              <a
                target="_black"
                href="https://www.google.com/maps/place/Jl.+Aria+Wiratanudatar,+Kabupaten+Cianjur,+Jawa+Barat/@-6.7891776,107.1677502,19.73z/data=!4m6!3m5!1s0x2e68534bbbf3b689:0x30107f0b7611f32e!8m2!3d-6.7893613!4d107.1678994!16s%2Fg%2F1jky4z962?entry=ttu"
              >
                <div className="flex ">
                  <img
                    src={location}
                    alt="kalpika logo"
                    className="mr-2 max-w-[30px] max-h-[30px]  lg:max-w-[40px] lg:max-h-[30px]"
                  ></img>
                  <div className="flex flex-col">
                    <h5 className="font-gilroyBold text-[16px] lg:text-[20px]">
                      Cianjur
                    </h5>
                    <p className="font-gilroyLight text-[14px] lg:text-[16px]">
                      Jl. Aria Wiratanudatar Km 8, Desa Kademangan, Cianjur
                      43292
                    </p>
                  </div>
                </div>
              </a>
              <div className="w-full lg:w-[400px] h-3/4 lg:h-[300px] p-4 hidden lg:block">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d598.5125404349212!2d107.1677501840582!3d-6.789177598278303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68534bbbf3b689%3A0x30107f0b7611f32e!2sJl.%20Aria%20Wiratanudatar%2C%20Kabupaten%20Cianjur%2C%20Jawa%20Barat!5e0!3m2!1sid!2sid!4v1717385711489!5m2!1sid!2sid"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>

          {/* Content for the second column */}
        </div>
      </section>

      {/* produk */}
      <section id="produk" className="lg:pt-[100px] pt-[90px]">
        <h1 className="text-center text-primary text-xl lg:text-[35px] font-bold tracking-wide mt-6 lg:mt-8 font-gilroyBold">
          Produk Kalpika Logistic
        </h1>
        <div className="grid grid-cols-1 gap-8 lg:my-12 lg:mx-12 mx-8 my-6">
          <div className="grid lg:grid-cols-2 gap-8 sm:grid-cols-1 lg:my-12 lg:mx-32 ">
            {/* Content for the first column */}
            <div className="lg:col-span-1 sm:col-span-full mx-auto lg:mx-0 mt-8 lg:mt-0  hidden lg:block ">
              <div className="mr-2 ">
                <img
                  src={perusahaan1}
                  alt="kalpika logo"
                  className="lg:w-[600px]"
                ></img>
              </div>
            </div>

            {/* Content for the second column */}
            <div className="lg:col-span-1 sm:col-span-full mx-4 mb-8">
              <h5 className="lg:text-[20px] text-[16px] font-gilroyBold lg:mb-1">
                Full Track Load (FTL)
              </h5>
              <h5 className="text-[14px] lg:text-[20px]  text-justify  font-light font-gilroyReg lg:pb-2">
                Layanan pengiriman barang melalui jalur darat dengan memberikan
                kuota sampai dengan kapasitas maksimal kendaraan, harga efisien
                dan waktu pengiriman lebih cepat.
              </h5>
              <ul className="list-outside text-[#7B7B7B] text-[16px] lg:text-[16px] text-justify font-gilroyReg lg:py-1 list-disc pl-6 space-y-2">
                <li>
                  Estimasi waktu pengiriman 1 hari untuk wilayah Jabodetabek dan
                  Jawa Barat.
                </li>
                <li>
                  Kapasitas maksimal kendaraan sampai dengan 5.500 kg atau 25
                  m3.
                </li>
              </ul>
            </div>
          </div>
          <div className="grid lg:grid-cols-2 gap-8 sm:grid-cols-1 lg:my-12 lg:mx-32">
            {/* Content for the second column */}
            <div className="lg:col-span-1 sm:col-span-full mx-4 mb-8">
              <h5 className="lg:text-[20px] text-[16px] font-gilroyBold lg:mb-1">
                Less Than Truckload (LTL)
              </h5>
              <h5 className="text-[14px] lg:text-[20px]  text-justify  font-light font-gilroyReg lg:pb-2">
                Layanan pengiriman barang melalui jalur darat dengan berat dan
                volume besar untuk beberapa titik tujuan pengantaran antar kota.
              </h5>
              <ul className="list-outside text-[#7B7B7B] text-[16px] lg:text-[16px] text-justify font-gilroyReg lg:py-1 list-disc pl-6 space-y-2">
                <li>
                  Estimasi waktu pengiriman 1 - 3 hari untuk wilayah Jabodetabek
                  dan Jawa Barat.
                </li>
              </ul>
            </div>
            {/* Content for the first column */}
            <div className="lg:col-span-1 sm:col-span-full mx-auto lg:mx-0 mt-8 lg:mt-0 hidden lg:block">
              <div className="mr-2 ">
                <img
                  src={perusahaan2}
                  alt="kalpika logo"
                  className="lg:w-[600px]"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* partner */}
      <section id="partner" className="lg:pt-[120px] pt-[90px]">
        <h1 className="text-center text-primary text-xl lg:text-[35px] font-bold tracking-wide lg:mb-[20px] font-gilroyBold">
          Rekanan Pengguna Jasa
        </h1>

        <div className="relative w-full max-w-full mx-auto ">
          <div className="overflow-hidden relative h-[600px]">
            {icon.map((icons, index) => (
              <Transition
                key={index}
                show={index === currentSlidePartner}
                enter="transition-opacity duration-1000"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-1000"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="absolute inset-0"
              >
                <div className="xl:mx-40 lg:mx-[90px] mx-6 my-6">
                  <h5 className="font-gilroyBold text-[18px] mb-12 text-center lg:text-left lg:text-[24px]">
                    {icons.title}
                  </h5>
                  <div className="grid lg:grid-cols-5 gap-4 grid-cols-2 lg:my-12 ">
                    {icons.images.map((list, index) => (
                      <div
                        className="lg:col-span-1 sm:col-span-1/2 mx-auto "
                        key={index}
                      >
                        <img
                          src={list.image}
                          alt={list.id}
                          className="lg:ml-auto  max-w-[80px] max-h-[80px] lg:max-w-[100px] lg:max-h-[100px]"
                        ></img>
                      </div>
                    ))}
                  </div>
                </div>
              </Transition>
            ))}
          </div>

          <button
            onClick={prevSlidePartner}
            type="button"
            class="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-prev
          >
            <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <svg
                class="w-4 h-4 text-black dark:text-gray-800 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
              <span class="sr-only">Previous</span>
            </span>
          </button>
          <button
            onClick={nextSlidePartner}
            type="button"
            class="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-next
          >
            <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <svg
                class="w-4 h-4 text-black dark:text-gray-800 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span class="sr-only">Next</span>
            </span>
          </button>
        </div>
      </section>
    </>
  );
};

export default LandingPage;
