import { useState } from "react";
import kalpika from "../assets/img/kalpika.png";
import location from "../assets/img/location.png";
import telepon from "../assets/img/TLP.png";
import whatsapp from "../assets/img/WA.png";
import email from "../assets/img/email.png";
import google from "../assets/img/google.png";
import apple from "../assets/img/apple.png";
import ModalOngkir from "../component/Modal/ModalOngkir";

export default function RootLayout() {
  const currentTime = new Date().getFullYear();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModalOngkir = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <footer className="border border-t-2 py-4 mt-12">
        <div className="grid xl:grid-cols-4 lg:grid-cols-2 gap-8 sm:grid-cols-1 lg:my-12 mx-24">
          <div className="lg:col-span-1 sm:col-span-full mx-auto">
            <div className="flex lg:items-center ">
              <img
                src={kalpika}
                alt="kalpika logo"
                className="max-w-[60px] px-2  "
              ></img>
              <h5 className="text-center text-primary font-gilroyMedium">
                Kalpika Logistic
              </h5>
            </div>
            <div className="flex items-center mt-4">
              <a
                target="_blank"
                href="https://www.google.com/maps/place/Kalpika+Logistics/@-6.9232953,107.5721823,17z/data=!3m1!4b1!4m6!3m5!1s0x2e68e72324377d4d:0x9cdeafda43de7a6d!8m2!3d-6.9233006!4d107.5747572!16s%2Fg%2F11r3s6fv_q?entry=ttu"
              >
                <div className="flex">
                  <img
                    src={location}
                    alt="kalpika logo"
                    className="w-[55px] h-[40px] px-2 "
                  ></img>
                  <div className="flex flex-col">
                    <h5 className="font-gilroyBold text-[14px] lg:text-left lg:text-[20px]">
                      Bandung
                    </h5>
                    <p className="font-gilroyLight text-[12px]  lg:text-left lg:text-[14px]">
                      Jl. Soekarno Hatta No. 56, Cibuntu, Kota Bandung 40212
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="lg:col-span-1 sm:col-span-full  mx-auto">
            <div className="px-2 pt-2 space-y-1 text-sm">
              <h5 className="text-primary block text-center lg:text-left font-gilroyReg text-md lg:text-lg">
                Informasi Layanan
              </h5>
              <a
                href="/pengiriman"
                className="text-black hover:text-[#8B8A8A] block lg:text-left text-center font-gilroyLight text-[12px] lg:text-[16px]"
              >
                Syarat Pengiriman
              </a>
              <a
                href="#"
                className="text-black hover:text-[#8B8A8A] block lg:text-left text-center font-gilroyLight text-[12px] lg:text-[16px]"
              >
                Cek Resi
              </a>
              <a
                onClick={openModalOngkir}
                className="cursor-pointer text-black hover:text-[#8B8A8A] block lg:text-left text-center font-gilroyLight text-[12px] lg:text-[16px]"
              >
                Cek Ongkir
              </a>
            </div>
          </div>
          <div className="lg:col-span-1 sm:col-span-full  mx-auto">
            <div className=" pt-2 pb-3 space-y-1 text-sm">
              <h5 className="text-primary block text-center lg:text-left font-gilroyReg text-md lg:text-lg">
                Info Kontak
              </h5>
              {/* <div className="flex">
              <div className="flex my-2">
                <img
                  src={telepon}
                  alt="kalpika logo"
                  className="max-w-[100px] pr-2 "
                ></img>
                <h5 className="font-gilroyLight text-[12px] lg:text-[14px]">
                  0228 XXXX XXX
                </h5>
              </div>
            </div> */}
              <div className="flex">
                <div className="flex my-2">
                  <img
                    src={whatsapp}
                    alt="kalpika logo"
                    className="max-w-[30px]  pr-2 "
                  ></img>
                  <a
                    href="https://wa.me/6281223223398"
                    target="_blank"
                    className="font-gilroyLight text-[12px] lg:text-[14px]"
                  >
                    +62 812 2322 3398
                  </a>
                </div>
              </div>
              <div className="flex">
                <div className="flex my-2">
                  <img
                    src={email}
                    alt="kalpika logo"
                    className="max-w-[30px]  pr-2 "
                  ></img>
                  <h5 className="font-gilroyLight text-[12px] lg:text-[14px]">
                    cs.kalpika@gmail.com
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:col-span-1 sm:col-span-full mx-auto">
            <div className="px-2 space-y-1">
              <h5 className="text-primary block text-center font-gilroyReg text-md lg:text-lg mb-4">
                Segera Hadir
              </h5>
              <img
                src={google}
                alt="kalpika logo"
                className="max-w-[250px] mx-auto px-2 "
              ></img>
              <img
                src={apple}
                alt="kalpika logo"
                className="max-w-[250px] mx-auto px-2 "
              ></img>
            </div>
          </div>
        </div>
        <div className="lg:col-span-1 sm:col-span-full mx-12 lg:my-4 my-8">
          <h5 className="text-black block text-center font-gilroyReg text-xs lg:text-[14px]">
            Copyright©{currentTime} PT. Eksak Guna Global. All rights reserved.
          </h5>
        </div>
      </footer>
      {modalIsOpen ? <ModalOngkir onCloseModal={closeModal} /> : null}
    </>
  );
}
