import { Route, Routes, BrowserRouter } from "react-router-dom";
import ProtectedRoutes from "./route/ProtecedRoute";
import Home from "./auth/Home";
import LandingPage from "./component/LandingPage";
import Pengiriman from "./component/Pengiriman";
import Karir from "./component/Karir";

const AppRouter = () => {
  return (
    <Routes history={BrowserRouter}>
      <Route element={<ProtectedRoutes />}>
        <Route element={<Home />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/pengiriman" element={<Pengiriman />} />
          <Route path="/karir" element={<Karir />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRouter;
