import { useLocation } from "react-router-dom";
import React, { useState } from "react";
import kalpikaLogo from "../assets/img/kalpika.png";

const Navbar = () => {
  const location = useLocation();
  const [isClick, setisClick] = useState(false);

  const toggleNavbar = () => {
    setisClick(!isClick);
  };

  const handleScroll = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <nav className="bg-white font-gilroy shadow-md fixed w-full z-50 top-0">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-24">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <a
                href="/"
                className="text-primary flex items-center text-[16px]"
              >
                <img
                  src={kalpikaLogo}
                  alt="kalpika logo"
                  className="max-w-[70px] mx-auto px-2 font-gilroyMedium"
                ></img>
                Kalpika Logistic
              </a>
            </div>
          </div>
          {location.pathname == "/" ? (
            <div className="hidden lg:block font-gilroyMedium">
              <div className="ml-4 flex items-center 2xl:space-x-36 xl:space-x-24 lg:space-x-8 md:space-x-6">
                <a
                  href="#perusahaan"
                  onClick={(e) => handleScroll(e, "perusahaan")}
                  className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                >
                  Perusahaan
                </a>
                <a
                  href="#testimoni"
                  onClick={(e) => handleScroll(e, "testimoni")}
                  className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                >
                  Testimoni
                </a>
                <a
                  href="#lokasi"
                  onClick={(e) => handleScroll(e, "lokasi")}
                  className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                >
                  Lokasi
                </a>
                <a
                  href="#produk"
                  onClick={(e) => handleScroll(e, "produk")}
                  className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                >
                  Produk
                </a>
                <a
                  href="#partner"
                  onClick={(e) => handleScroll(e, "partner")}
                  className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                >
                  Partner
                </a>
                <a
                  href="/"
                  className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                >
                  Peluang Karir
                </a>
                {/* <a
                  href="/"
                  className="text-black hover:bg-[#302BB2] hover:text-white rounded-lg p-2"
                >
                  Indonesia | English
                </a> */}
              </div>
            </div>
          ) : (
            <div className="hidden lg:block font-gilroyMedium">
              <div className="ml-4 flex items-center 2xl:space-x-36 xl:space-x-24 lg:space-x-8 md:space-x-6">
                <a
                  href="/"
                  className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                >
                  Perusahaan
                </a>
                <a
                  href="/"
                  className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                >
                  Testimoni
                </a>
                <a
                  href="/"
                  className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                >
                  Lokasi
                </a>
                <a
                  href="/"
                  className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                >
                  Produk
                </a>
                <a
                  href="/"
                  className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                >
                  Partner
                </a>
                <a
                  href="/"
                  className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                >
                  Peluang Karir
                </a>
                {/* <a
                  href="/"
                  className="text-black hover:bg-[#302BB2] hover:text-white rounded-lg p-2"
                >
                  Indonesia | English
                </a> */}
              </div>
            </div>
          )}

          <div className="lg:hidden  flex items-center">
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-black md:text-black hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              onClick={toggleNavbar}
            >
              {isClick ? (
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      {isClick && (
        <div className="">
          {location.pathname == "/" ? (
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 text-sm font-gilroyMedium">
              <a
                href="#perusahaan"
                onClick={((e) => handleScroll(e, "perusahaan"), toggleNavbar)}
                className="py-2 mx-auto relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Perusahaan
              </a>
              <a
                href="#testimoni"
                onClick={((e) => handleScroll(e, "testimoni"), toggleNavbar)}
                className="py-2 mx-auto  relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Testimoni
              </a>
              <a
                href="#lokasi"
                onClick={((e) => handleScroll(e, "lokasi"), toggleNavbar)}
                className="py-2 mx-auto  relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Lokasi
              </a>
              <a
                href="#produk"
                onClick={((e) => handleScroll(e, "produk"), toggleNavbar)}
                className="py-2 mx-auto  relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Produk
              </a>
              <a
                href="#partner"
                onClick={((e) => handleScroll(e, "partner"), toggleNavbar)}
                className="py-2 mx-auto  relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Partner
              </a>
              <a
                href="/"
                className="py-2 mx-auto  relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Peluang Karir
              </a>
              {/* <a
                  href="/"
                  className="text-black block text-center hover:bg-[#302BB2] hover:text-white rounded-lg p-2"
                >
                  Indonesia | English
                </a> */}
            </div>
          ) : (
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 text-sm">
              <a
                href="/"
                onClick={((e) => handleScroll(e, "perusahaan"), toggleNavbar)}
                className="py-2 mx-auto relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Perusahaan
              </a>
              <a
                href="/"
                className="py-2 mx-auto  relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Testimoni
              </a>
              <a
                href="/"
                className="py-2 mx-auto  relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Lokasi
              </a>
              <a
                href="/"
                className="py-2 mx-auto  relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Produk
              </a>
              <a
                href="/"
                className="py-2 mx-auto  relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Partner
              </a>
              <a
                href="/"
                className="py-2 mx-auto  relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Peluang Karir
              </a>
              {/* <a
                  href="/"
                  className="text-black block text-center hover:bg-[#302BB2] hover:text-white rounded-lg p-2"
                >
                  Indonesia | English
                </a> */}
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
