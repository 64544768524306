import React, { useState } from "react";
import karir1 from "../assets/img/karir1.png";
import karir2 from "../assets/img/karir2.png";

const Karir = () => {
  const data_karir = [
    {
      id: 1,
      image: { karir1 },
      name_job: "Driver",
      jobdesk:
        "Mengemudikan truk dari titik ke titik yang lain untuk mengirimkan barang sampai destinasinya",
      syarat: [
        "Memiliki SIM yang sesuai dengan tipe kendaraan.",
        "Dapat membawa barang dari titik penjemputan sampai ke titik tujuan dengan aman.",
        "Berkendara sesuai aturan lalu lintas yang berlaku.",
        "Memastikan barang yang dikirim sampai tepat waktu atau sesuai jadwal yang sudah ditentukan.",
      ],
    },
    {
      id: 2,
      image: "./assets/img/karir2.png",
      name_job: "Admin",
      jobdesk: "Input data dari driver",
      syarat: [
        "Mampu mengoperasikan komputer.",
        "Teliti dan rapi dalam bekerja.",
        "Memiliki kemampuan komunikasi yang baik.",
      ],
    },
  ];

  const [openJobDetails, setOpenJobDetails] = useState(false);

  const toggleNavbar = (id) => {
    setOpenJobDetails((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <>
      {/* karir */}
      <section className="lg:pt-[100px] pt-[90px] lg:mx-[50px] mx-8 mb-12">
        <h1 className="text-primary text-[20px] lg:text-[48px] lg:mb-[30px] mt-6 font-extrabold font-gilroyBold">
          Mari Bergabung Bersama Kami.
        </h1>
        <h5 className="text-black text-[16px] lg:text-[24px] lg:mb-[70px] mt-2 font-regular font-gilroyReg">
          Silakan pilih posisi yang tersedia.
        </h5>
        <div className="grid lg:grid-cols-1 gap-8 sm:grid-cols-1 lg:my-12 lg:mx-32">
          <h5 className="text-center text-black text-[16px] lg:text-[24px] lg:mb-[70px] mt-2 font-bold font-gilroyBold">
            Lowongan Kerja
          </h5>
          {/* Content for the second column */}
          {data_karir.map((list) => (
            <div className="lg:col-span-1 sm:col-span-full mx-4" key={list.id}>
              <img
                src={karir1}
                alt="syarat logo"
                className="mb-12 lg:mx-auto w-full h-[100px] lg:w-[450px] lg:h-[200px]"
              ></img>

              <h5 className="text-black text-[16px] lg:text-[24px] mt-2 font-bold font-gilroyBold">
                {list.name_job}
              </h5>

              <div className="flex items-center">
                <button
                  onClick={() => toggleNavbar(list.id)}
                  className="text-[16px] lg:text-[20px] text-justify font-regular font-gilroyReg underline cursor-pointer inline-flex items-center justify-center rounded-md text-black md:text-black hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  Information Detail
                  {openJobDetails[list.id] ? (
                    <svg
                      className="w-6 h-6 p-1"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 16 10"
                    >
                      <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z" />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6 p-1"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 10 16"
                    >
                      <path d="M3.414 1A2 2 0 0 0 0 2.414v11.172A2 2 0 0 0 3.414 15L9 9.414a2 2 0 0 0 0-2.828L3.414 1Z" />
                    </svg>
                  )}
                </button>
              </div>
              {openJobDetails[list.id] && (
                <div>
                  <h5 className="text-[#7B7B7B] text-[16px] lg:text-[18px] text-justify font-gilroyReg lg:py-3">
                    Jobdesk Pekerjaan :
                  </h5>
                  <h5 className="text-[#7B7B7B] text-[16px] lg:text-[16px] text-justify font-gilroyReg lg:py-3">
                    {list.jobdesk}
                  </h5>
                  <h5 className="text-[#7B7B7B] text-[16px] lg:text-[18px] mt-2 font-gilroyReg">
                    Persyaratan :
                  </h5>
                  <ul className="list-outside text-[#7B7B7B] text-[16px] lg:text-[16px] text-justify font-gilroyReg lg:py-3 list-disc pl-4 space-y-2">
                    {list.syarat.map((syarat, index) => (
                      <li key={index}>{syarat}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Karir;
