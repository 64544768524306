import React from "react";
import syarat from "../assets/img/syarat.png";

const pengiriman = () => {
  return (
    <>
      {/* persyaratan */}
      <section className="lg:pt-[100px] pt-[90px] lg:mx-[50px] mx-8">
        <h1 className=" text-primary text-xl  lg:text-[48px] lg:mb-[30px] mt-6 font-Extrabold font-gilroyBold text-justify">
          Syarat Pengiriman
        </h1>
        <h5 className=" text-black text-sm  lg:text-[24px] lg:mb-12 mt-2 font-reguler font-gilroyReg text-justify">
          Bacalah persyaratan pengiriman di bawah ini.
        </h5>
        <div className="grid lg:grid-cols-1 gap-8 sm:grid-cols-1 lg:my-2 lg:mx-6">
          {/* Content for the second column */}
          <div className="lg:col-span-1 sm:col-span-full">
            <ul className="list-decimal">
              <li className="flex items-start mb-2">
                <span className="w-6 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg">
                  1.
                </span>
                <span className="flex-1 text-black text-sm lg:text-[18px] mt-2 font-reguler font-gilroyReg text-justify">
                  Pihak pengirim bertanggung jawab penuh atas isi kiriman dan
                  dilarang memasukan dalam kemasan barang-barang berikut :
                  <ul className="text-[12px] lg:text-[16px]  text-justify  font-light font-gilroyLight lg:py-3 list-disc list-outside pl-4 space-y-2 pt-3">
                    <li>Uang tunai dalam bentuk mata uang apapun.</li>
                    <li>Surat Pos, Warkat Pos, Kartu Pos.</li>
                    <li>
                      Surat-surat berharga seperti cek, bilyet, giro, saham,
                      sertifikat, ijazah, dan sejenisnya, serta barang berharga
                      seperti arloji dan perhiasan logam.
                    </li>
                    <li>
                      Barang cetak, rekaman, atau barang lain yang dapat
                      menggangu ketertiban umum.
                    </li>
                    <li>
                      Barang yang mudah meledak, berbahaya, beracun, atau dapat
                      merusak barang lain.
                    </li>
                    <li>
                      Narkotika, ganja, atau obat-obatan terlarang lainnya.
                    </li>
                    <li>
                      Barang ilegal yang melanggar peraturan undang-undang untuk
                      dikirim tanpa dilengkapi surat izin atau lampiran dari
                      instansi terkait (seperti Bea Cukai, BPOM, BNN, Balai
                      karantina dan instansi lainnya).
                    </li>
                  </ul>
                </span>
              </li>
              <li className="flex items-start mb-2">
                <span className="w-6 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg">
                  2.
                </span>
                <span className="flex-1 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg text-justify">
                  Isi kiriman yang tidak sesuai dengan informasi yang diberikan
                  adalah tanggung jawab pengirim dan bisa dituntut secara hukum
                  yang berlaku. Pengangkut berhak memeriksa kiriman yang
                  mencurigakan sesuai dengan hukum di Indonesia.
                </span>
              </li>
              <li className="flex items-start mb-2">
                <span className="w-6 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg">
                  3.
                </span>
                <span className="flex-1 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg text-justify">
                  Jika barang kiriman mengalami kekurangan, kerusakan, atau
                  hilang, PT.Kalpika Logistic akan mengganti hingga maksimal 10
                  kali biaya pengiriman untuk barang yang terkena masalah
                  tersebut.
                </span>
              </li>

              <li className="flex items-start mb-2">
                <span className="w-6 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg">
                  4.
                </span>
                <span className="flex-1 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg text-justify">
                  Pengangkut tidak bertanggung jawab atas hal-hal sebagai
                  berikut :
                  <ul className="text-[12px] lg:text-[16px]  text-justify  font-light font-gilroyLight lg:py-3 list-disc list-outside pl-4 space-y-2 pt-3">
                    <li>
                      Resiko teknis yang mungkin terjadi selama pengangkutan
                      yang dapat menyebabkan kerusakan atau perubahan fungsi
                      barang seperti mesin, barang elektronik (seperti TV,
                      Komputer, AC, kulkas, mesin cuci, handphone, dll) akibat
                      keadaan memaksa.
                    </li>
                    <li>
                      Tuntutan, keluhan, atau permintaan bukti tanda terima
                      pengiriman setalah 1 bulan sejak tanggal pengiriman.
                    </li>
                    <li>
                      Kerusakan, kekurangan, atau kehilangan yang disebabkan
                      oleh kecelakaan, kerusuhan, bencana alam, perang,
                      kebakaran, epidemi, atau kejadian force majeur lainnya.
                    </li>
                    <li>
                      Keterlambatan pengiriman ke kota tujuan yang disebabkan
                      oleh keadaan yang memaksa.
                    </li>
                    <li>
                      Penyitaan atau pemusnahan barang oleh instansi
                      pemerintahan terkait, sebagai konsekuensi hukum dari
                      kondisi kiriman yang bersangkutan.
                    </li>
                    <li>
                      Kerugian, kerusakan, atau pembusukan pada jenis pengiriman
                      tertentu seperti barang cair, pecah belah, makanan,
                      buah-buahan, dan tanaman jika tidak dipacking sesuai
                      dengan standart yang berlaku di PT.Kalpica Logistic.
                    </li>
                  </ul>
                </span>
              </li>
              <li className="flex items-start mb-2">
                <span className="w-6 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg">
                  5.
                </span>
                <span className="flex-1 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg text-justify">
                  Semua klaim akan ditangni oleh kantor pengiriman perusahaan
                  bersama dengan pelanggan. Untuk mengajukan klaim, diperlukan :
                  <ul className="text-[12px] lg:text-[16px]  text-justify  font-light font-gilroyLight lg:py-3 list-disc list-outside pl-4 space-y-2 pt-3">
                    <li>
                      Surat Berita Acara tentang kurasakan atau kehilangan yang
                      harus ditandatangani oleh penerima barang dan diketahui
                      oleh pihak yang mengantar mengangkut di lokasi.
                    </li>
                    <li>
                      Dokumen pendukung seperti faktur, kwitansi, dan bukti
                      tanda terima asli yang ditandatangani oleh penerima
                      barang.
                    </li>
                  </ul>
                </span>
              </li>
              <div className="grid grid-cols-12 gap-4">
                <div className="lg:col-span-8 col-span-12">
                  <ul className="list-decimal">
                    <li className="flex items-start mb-2">
                      <span className="w-6 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg">
                        6.
                      </span>
                      <span className="flex-1 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg text-justify">
                        Sebelum mengirim barang ke tempat tujuan, setiap barang
                        titipan harus melalui proses serah terima di gudang
                        penerimaan barang PT.Kalpika Logistic untuk pembutan
                        bukti tanda terima (BTT).
                      </span>
                    </li>
                    <li className="flex items-start mb-2">
                      <span className="w-6 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg">
                        7.
                      </span>
                      <span className="flex-1 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg text-justify">
                        Pihak pengirim harus memberikan informasi lengkap
                        tentang barang yang akan dikirim, seperti alamat
                        pengirim dan penerima, nomor telepon keduanya, serta
                        detail isi kiriman.
                      </span>
                    </li>
                    <li className="flex items-start mb-2">
                      <span className="w-6 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg">
                        8.
                      </span>
                      <span className="flex-1 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg text-justify">
                        Jika biaya pengiriman ditagihkan kepada penerima dan
                        mereka tidak mau membayarnya, maka pihak pengirim harus
                        menggantinya. Untuk menggunakan fasilitas ini, pelanggan
                        harus mengisi Formulir Tagihan Tujuan atau membuat surat
                        pernyataan bahwa pihak pengirim bersedia membayar biaya
                        tagihan tujuan jika penerima tidak membayar.
                      </span>
                    </li>
                    <li className="flex items-start mb-2">
                      <span className="w-6 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg">
                        9.
                      </span>
                      <span className="flex-1 text-black text-sm  lg:text-[18px] mt-2 font-reguler font-gilroyReg text-justify">
                        Perubahan alamat pengiriman atau pembatalan hanya bisa
                        dilakukan pada hari yang sama saat barang dikirim
                        sebelum jam 17:00 waktu setempat. Jika terlambat akan
                        berlaku kebijakan sesuai aturan di PT.Kalpika Logistic.
                      </span>
                    </li>
                  </ul>

                  <h5 className=" text-black text-sm  lg:text-[18px] mt-6 font-reguler font-gilroyReg text-justify">
                    Sekian ketentuan dan syarat-syarat pengiriman yang harus
                    dipatuhi di PT.Kalpika Logistic. Terima kasih atas perhatian
                    dan kerja samanya.
                  </h5>
                </div>
                <div className="lg:col-span-4 col-span-12 ">
                  <img
                    src={syarat}
                    alt="syarat logo"
                    className="mb-12 lg:ml-auto max-w-[150px] max-h-[350px] lg:max-w-[300px] lg:max-h-[600px] mx-auto"
                  ></img>
                </div>
              </div>
            </ul>
          </div>
          {/* Content for the first column */}
          <div className="lg:col-span-1 sm:col-span-full mx-auto lg:mx-0 mt-8 lg:mt-0 flex justify-end">
            {/* <Image
              className="mb-12 lg:ml-auto max-w-[150px] max-h-[350px] lg:max-w-[300px] lg:max-h-[600px]"
              src="/syarat.png"
              alt="kalpika logo"
              width={500}
              height={500}
              priority
            /> */}
          </div>
        </div>
      </section>
    </>
  );
};
export default pengiriman;
