import { useState, useEffect } from "react";
import ongkir from "../../assets/img/ongkir.png";

function ModalOngkir({ onCloseModal }) {
  const onCloseModalProperty = () => {
    onCloseModal();
  };

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-60 z-50"></div>
      <div
        tabIndex={-1}
        aria-hidden="true"
        className="overflow-y-auto overflow-x-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className="relative p-2 w-full mx-auto my-[10%]  max-w-screen-2xl max-h-full">
          {/* Modal content */}
          <div className="relative bg-white rounded-xl shadow dark:bg-gray-700 p-4">
            {/* Modal header */}
            <div className="flex items-center justify-between  md:pl-5 md:pt-5 md:pr-5">
              <h3 className="text-[22px] lg:text-[32px] font-gilroyBold text-primary dark:text-white text-center">
                Pengecekan Tarif Ongkir
              </h3>
              <button
                onClick={onCloseModalProperty}
                type="button"
                className="end-2.5 text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xl w-6 h-6 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <p className="text-[12px] lg:text-[17px] font-gilroyLight text-black lg:px-5">
              Berikut merupakan daftar layanan beserta tarif ongkos kirim.
            </p>
            {/* Modal body */}
            <div className="p-4 md:p-5 flex space-x-4 mt-6">
              <div class="relative overflow-x-auto lg:w-[80%] w-[100%]">
                <table class="w-full text-sm text-center rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-[1px] lg:text-[16px] text-primary bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-3">
                        Layanan
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Tarif Ongkir
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-[12px] lg:text-[14px]">
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td class="px-6 py-4">Reguler</td>
                      <td class="px-6 py-4">IDR 1,200 (per kg)</td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td class="px-6 py-4">CDD (Double)</td>
                      <td class="px-6 py-4">IDR 1,400,000</td>
                    </tr>
                    <tr class="bg-white dark:bg-gray-800">
                      <td class="px-6 py-4">CDE (Engkel)</td>
                      <td class="px-6 py-4">IDR 1,800,000</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="relative overflow-x-auto lg:w-[20%] lg:block hidden">
                <img
                  src={ongkir}
                  alt="kalpika logo"
                  className="mr-2 lg:ml-auto max-w-[250px] max-h-[150px] lg:max-w-[550px] lg:max-h-[300px]"
                ></img>
              </div>
            </div>

            <p className="text-[12px] lg:text-[13px] font-gilroyLight text-gray-400 p-5 text-center">
              *Harga sewaktu - waktu dapat berubah sesuai dengan ketentuan yang
              berlaku.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalOngkir;
